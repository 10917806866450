<template>
  <div class="widgetContainer contactAccountTypeForm bg-white">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <span class="el-icon-close cancel" @click="cancel" />
        <p class="title">
          {{
            $t(
              getCreateContactData && getCreateContactData.id
                ? 'contact_details_EditTitle'
                : 'contact_Details_NavTitle'
            )
          }}
        </p>
        <span class="el-icon-close" @click="drawerClose" />
      </div>
    </div>

    <div class="widgetContainer__body">
      <h4 class="wise-form__header">
        {{ $t('contact_Details_SelectAccType') }}
      </h4>
      <div class="listSimpleArrows no-status">
        <ul>
          <li @click="next('intrabank')">
            <div class="details">
              <p class="label">
                {{ $t('accountType_intrabank') }}
              </p>
              <p class="value">
                {{ intrabankText }}
              </p>
            </div>
            <div class="action">
              <span class="icon-arrow-right" />
            </div>
          </li>
          <li @click="next('ach')">
            <div class="details">
              <p class="label">
                {{ $t('accountType_ach') }}
              </p>
              <p class="value">
                {{ achText }}
              </p>
            </div>
            <div class="action">
              <span class="icon-arrow-right" />
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="el-drawer__footer">
      <el-button 
        class="width-100 el-button__brand brand" 
        type="primary"
        disabled="!isFormValid"
        @click="done()">
        {{ $t('done') }}
      </el-button>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
  computed: {
    ...mapGetters('contact', ['getCreateContactData']),
    intrabankText() {
      if (this.getCreateContactData.intrabank) {
        const accountNumber = this.getCreateContactData.intrabank.accountNumber;
        const maskedNo = accountNumber.slice(accountNumber.length - 4);
        return this.$t('contact_AccountNumber', { value1: ' ***' + maskedNo });
      }
      return '';
    },
    achText() {
      if (this.getCreateContactData.ach) {
        const accountNumber = this.getCreateContactData.ach.accountNumber;
        const maskedNo = accountNumber.slice(accountNumber.length - 4);
        return this.$t('contact_AccountNumber', { value1: ' ***' + maskedNo });
      }
      return '';
    },
    isFormValid() {
      return (
        this.getCreateContactData &&
        (this.getCreateContactData.ach || this.getCreateContactData.intrabank)
      );
    }
  },
  methods: {
    ...mapMutations('contact', ['updateCreateContactData']),
    cancel() {
      const contact = this.getCreateContactData;
      if (contact.id) {
        this.$router.push('/contact/details');
      } else {
        this.goToPreviousScreen();
      }
    },
    done() {
      if (this.isFormValid) {
        this.goToPreviousScreen();
      }
    },
    next(type) {
      /** @type {import('@m/contact/store/state').contactState['createContactData'] */
      const contact = this.getCreateContactData;
      this.updateCreateContactData({ ...contact, selectedAccountType: type });
      this.$router.replace('/contact/accountForm');
    }
  }
};
</script>
<style lang="scss" scoped>
.contactAccountTypeForm {
  .header-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 41px;
    padding-bottom: 6px;
  }

  ul {
    li {
      padding-top: 10px;
      padding-bottom: 10px;

      .value {
        color: #3c3c4399;
        padding-top: 4px;
      }
    }
  }
  .submit.disabled {
    color: gray;
  }
}
</style>
